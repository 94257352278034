import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { GraphqlClientService } from '@core/services/graphql-client.service';
import { GET_CONTAINER_TYPES_QUERY } from '@shared/gql-shared-queries';
import { catchError, Subject, take, takeUntil, tap } from 'rxjs';
import { ShipmentMethodDetail } from '../../models/shipment-method-details';

@Component({
  selector: 'hmt-shipment-method-selector',
  templateUrl: './shipment-method-selector.component.html',
  styleUrls: ['./shipment-method-selector.component.scss'],
})
export class ShipmentMethodSelectorComponent implements OnInit, OnDestroy {
  @Input() initialLoadType: string;
  @Input() initialLoadDetails: ShipmentMethodDetail[] = [];
  @Input() viewOnly = false;
  @Input() disabled = false;
  @Input() radioHidden = false;
  @Output() loadDetailsChange = new EventEmitter<{ loadDetails: ShipmentMethodDetail[]; shipmentMethod: string }>();

  private $destroy: Subject<void> = new Subject();
  formGroup: FormGroup;
  loadTypes = [
    { type: 'FCL', checked: false },
    { type: 'LCL', checked: false },
  ];
  containerTypes: { id: string; name: string }[] = [];
  selectedLoadType: string;

  fb: FormBuilder = inject(FormBuilder);
  graphqlClient: GraphqlClientService = inject(GraphqlClientService);

  ngOnInit(): void {
    this.fetchContainerTypes();
    this.initializeForm();
  }

  initializeForm(): void {
    // Check if it's create mode
    const isCreateMode = this.initialLoadDetails.length === 0;

    // Set default values for create mode
    if (isCreateMode) {
      this.selectedLoadType = 'FCL';
      this.initialLoadDetails = [{ containerType: '', noOfLoads: null, volumeWeight: null }];
    } else {
      this.selectedLoadType = this.initialLoadType || 'FCL';
    }

    const loadType = this.loadTypes.find(lt => lt.type === this.selectedLoadType);
    if (loadType) {
      loadType.checked = true;
    }

    if (this.selectedLoadType === 'FCL') {
      this.formGroup = this.fb.group({
        fcl: this.fb.array(
          this.initialLoadDetails.map(loadDetail =>
            this.fb.group({
              containerType: [{ value: loadDetail.containerType, disabled: this.viewOnly }, [Validators.required]],
              containerCount: [
                { value: loadDetail.noOfLoads, disabled: this.viewOnly },
                [Validators.required, Validators.min(1)],
              ],
            })
          )
        ),
        lcl: this.fb.array([]),
      });
    } else {
      this.formGroup = this.fb.group({
        lcl: this.fb.array(
          this.initialLoadDetails.map(loadDetail =>
            this.fb.group({
              loadCount: [{ value: loadDetail.noOfLoads, disabled: this.viewOnly }, Validators.required],
              volumeWeight: [
                { value: loadDetail.volumeWeight, disabled: this.viewOnly },
                [Validators.required, Validators.min(1)],
              ],
            })
          )
        ),
        fcl: this.fb.array([]),
      });
    }

    // If it's create mode and FCL, ensure there's at least one empty row
    if (isCreateMode && this.selectedLoadType === 'FCL' && this.fclArray.length === 0) {
      this.addFCL();
    }

    this.formGroup.valueChanges.pipe(takeUntil(this.$destroy)).subscribe(() => {
      this.emitLoadDetailsChange();
    });

    // Add this new line to trigger change detection
    this.formGroup.updateValueAndValidity();
  }

  emitLoadDetailsChange(): void {
    const loadDetails = this.getLoadDetails();
    this.loadDetailsChange.emit({ loadDetails: loadDetails, shipmentMethod: this.selectedLoadType });
  }

  getLoadDetails(): ShipmentMethodDetail[] {
    if (this.selectedLoadType === 'FCL') {
      return this.formGroup.get('fcl')?.value.map(item => ({
        containerType: item.containerType,
        noOfLoads: item.containerCount,
        volumeWeight: 0,
      }));
    } else {
      return this.formGroup.get('lcl')?.value.map(item => ({
        containerType: '',
        noOfLoads: item.loadCount,
        volumeWeight: item.volumeWeight,
      }));
    }
  }

  getErrorMessage(controlName: string, formGroup: FormGroup): string {
    const control = formGroup.get(controlName);
    if (control.hasError('required')) {
      return 'This field is required';
    }
    if (control.hasError('min')) {
      return 'Value must be greater than 0';
    }
    return '';
  }

  fetchContainerTypes() {
    this.graphqlClient
      .query<{ containerTypes: { _id: string; name: string }[] }, undefined>(GET_CONTAINER_TYPES_QUERY)
      .pipe(
        take(1),
        tap(response => {
          const containerTypes = response?.containerTypes;
          this.containerTypes = containerTypes.map(containerType => ({
            id: containerType._id,
            name: containerType.name,
          }));
          this.initializeForm();
        }),
        catchError(async err => console.error('Error fetching container types', err))
      )
      .subscribe();
  }

  createFclGroup(): FormGroup {
    return this.fb.group({
      containerType: [{ value: '', disabled: this.viewOnly }, [Validators.required]],
      containerCount: [{ value: '', disabled: this.viewOnly }, [Validators.required, Validators.min(1)]],
    });
  }

  createLclGroup(): FormGroup {
    return this.fb.group({
      loadCount: ['', [Validators.required, Validators.min(1)]],
      volumeWeight: ['', [Validators.required, Validators.min(0)]],
    });
  }

  changeLoadType(loadType: MatRadioChange) {
    this.selectedLoadType = loadType.value;
    if (loadType.value === 'FCL') {
      this.addFCL();
      this.lclArray.clear();
    } else {
      this.addLCL();
      this.fclArray.clear();
    }
    this.emitLoadDetailsChange();
  }

  get fclArray(): FormArray {
    return this.formGroup?.get('fcl') as FormArray;
  }

  get lclArray(): FormArray {
    return this.formGroup?.get('lcl') as FormArray;
  }

  addFCL() {
    const itemForm = this.createFclGroup();
    this.fclArray.push(itemForm);
    this.emitLoadDetailsChange();
  }

  addLCL() {
    const itemForm = this.createLclGroup();
    this.lclArray.push(itemForm);
    this.emitLoadDetailsChange();
  }

  deleteItem(index: number) {
    this.fclArray.removeAt(index);
    // If it's the last item, add an empty one
    if (this.fclArray.length === 0) {
      this.addFCL();
    }
    this.emitLoadDetailsChange();
  }

  selectContainerType(containerType: MatSelectChange) {}

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
