import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JobUpdateNotification } from '@core/models/job-updates-notification.model';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { GraphqlClientService } from '@core/services/graphql-client.service';
import { ClearJobUpdates } from '@core/store/job-updates/job-updates.actions';
import { JobUpdatesState } from '@core/store/job-updates/job-updates.state';
import { FieldWrapper } from '@ngx-formly/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { FIND_TENDERS_BY_JOB_REF_ID_QUERY, GET_SERVICE_CONFIGS_QUERY } from '@shared/gql-shared-queries';
import { WorkflowState } from 'app/json-schema-forms/store/workflow.state';
import { filter, mergeMap, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { TenderStatus } from '../../enums/tender-status.enum';
import { Order } from '../../models/order.model';
import { ServiceConfig } from '../../models/service-config.model';
import { ServiceInfo } from '../../models/service-info.model';
import { ServiceInfoView } from '../../models/service-info.view.model';
import { ServiceOption } from '../../models/service-option.model';
import { ServiceOptionView } from '../../models/service-option.view.model';
import { Shipment } from '../../models/shipment.model';
import { ShipmentView } from '../../models/shipment.view.model';
import {
  CreateAssignedService,
  CreateAssignServiceOption,
  DeselectSelectableShipment,
  FetchShipmentsByIds,
  ResetServiceOptionState,
  SelectOption,
  SelectSelectableShipment,
  SelectService,
  UpdateUnSmartServices,
} from '../../store/wizard.actions';
import { WizardState } from '../../store/wizard.state';
import { ViewOrderCardsComponent } from '../view-order-cards/view-order-cards.component';

interface SelectableServiceConfig {
  serviceName: string;
  _id: string;
  selected?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'app-shipment-reference-with-services',
  templateUrl: './shipment-reference-with-services.component.html',
  styleUrl: './shipment-reference-with-services.component.scss',
})
export class ShipmentReferenceWithServicesComponent extends FieldWrapper implements OnInit, OnDestroy {
  private readonly store$ = inject(Store);
  injectableScreen = this.store$.select(WorkflowState.getInjectedScreen);
  shipments$ = this.store$.select(WizardState.getShipments);
  selectedServices$ = this.store$.select(WizardState.getSelectedServicesByOptionId);
  tenderValidity$ = this.store$.select(WizardState.getCreateTenderValidity);
  selectedOptions$ = this.store$.select(WizardState.getActiveOptionIdWithShipmentId);
  latestJobUpdates$: Observable<JobUpdateNotification> = this.store$.select(JobUpdatesState.getLatestJobUpdates);
  jobFile$ = this.store$.select(WizardState.getJobFile);

  private readonly dialogHandlerService = inject(DialogHandlerService);
  private readonly destroy$ = new Subject<void>();
  private readonly action$ = inject(Actions);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly gqlService = inject(GraphqlClientService);
  private readonly activatedRoute = inject(ActivatedRoute);

  tenderStatus = TenderStatus;
  serviceConfigs: SelectableServiceConfig[] = [];
  allSelected: boolean = false;
  allDisabled: boolean = false;

  ngOnInit(): void {
    this.jobFile$
      .pipe(
        take(1),
        tap(jobFile => {
          if (jobFile.smartened) {
            this.loadSmartenedJobData();
          } else {
            this.serviceConfigs = [];
            this.loadUnSmartenedJobData();
          }
        })
      )
      .subscribe();
  }

  private loadUnSmartenedJobData(): void {
    this.latestJobUpdates$
      .pipe(
        takeUntil(this.destroy$),
        filter((jobUpdate: JobUpdateNotification) => {
          return jobUpdate && jobUpdate.entityType === 'TENDER';
        }),
        tap((jobUpdate: JobUpdateNotification) => {
          if (!jobUpdate) {
            return;
          }
        })
      )
      .subscribe();

    this.gqlService
      .query<{ hmtServiceConfigs: ServiceConfig[] }, null>(GET_SERVICE_CONFIGS_QUERY, null)
      .pipe(
        take(1),
        tap((data: { hmtServiceConfigs: ServiceConfig[] }) => {
          this.field.options.formState.validForTender = false;
          this.serviceConfigs = data.hmtServiceConfigs.map(service => ({ ...service, selected: false }));
          this.cdr.detectChanges();
          this.disableTenderedUnSmartenedServices();
        })
      )
      .subscribe();
  }

  private loadSmartenedJobData(): void {
    this.tenderValidity$
      .pipe(
        takeUntil(this.destroy$),
        tap(validity => (this.options.formState.validForTender = validity))
      )
      .subscribe();

    this.latestJobUpdates$
      .pipe(
        takeUntil(this.destroy$),
        filter((jobUpdate: JobUpdateNotification) => {
          return jobUpdate && jobUpdate.entityType === 'SHIPMENT';
        }),
        tap((jobUpdate: JobUpdateNotification) => {
          if (!jobUpdate) {
            return;
          }
          const shipment: Shipment = jobUpdate.data as unknown as Shipment;
          this.store$.dispatch(new ResetServiceOptionState());
          this.store$.dispatch(new FetchShipmentsByIds([shipment._id]));
          this.buildState(shipment);
        })
      )
      .subscribe();

    this.shipments$
      .pipe(
        take(1),
        tap(shipments => {
          this.store$.dispatch(new ResetServiceOptionState());
          shipments.forEach(shipment => this.buildState(shipment));
        })
      )
      .subscribe();

    this.action$
      .pipe(
        takeUntil(this.destroy$),
        ofActionSuccessful(FetchShipmentsByIds),
        mergeMap(_ => this.shipments$.pipe(take(1))),
        tap(shipments => {
          this.store$.dispatch(new ResetServiceOptionState());
          shipments.forEach(shipment => this.buildState(shipment));
        })
      )
      .subscribe();
  }

  private buildState(shipment: Shipment): void {
    if (
      shipment.serviceOptions.some(
        option => option.servicesTenderStatus === 'PARTIALLY_ABANDONED' || option.servicesTenderStatus === 'ABANDONED'
      )
    ) {
      this.store$.dispatch(new SelectSelectableShipment({ ...shipment, selected: true, disabled: true }));
      shipment.serviceOptions.forEach(option => {
        if (option?.servicesTenderStatus === 'PARTIALLY_ABANDONED' || option?.servicesTenderStatus === 'ABANDONED') {
          this.store$.dispatch(
            new CreateAssignServiceOption(shipment._id, { ...option, selected: true, disabled: true })
          );
          option.services.forEach(service => {
            this.store$.dispatch(
              new CreateAssignedService(option._id, {
                ...service,
                selected: service.tenderStatus !== 'UNASSIGNED',
                disabled: service.tenderStatus !== 'UNASSIGNED',
              })
            );
          });
        } else {
          this.store$.dispatch(
            new CreateAssignServiceOption(shipment._id, {
              ...option,
              selected: option.servicesTenderStatus !== 'UNASSIGNED',
              disabled: option.servicesTenderStatus !== 'UNASSIGNED',
            })
          );
          option.services.forEach(service => {
            this.store$.dispatch(
              new CreateAssignedService(option._id, {
                ...service,
                selected: service.tenderStatus !== 'UNASSIGNED',
                disabled: service.tenderStatus !== 'UNASSIGNED' || option.servicesTenderStatus === 'UNASSIGNED',
              })
            );
          });
        }
      });
    } else {
      shipment.serviceOptions.forEach(option => {
        if (option?.servicesTenderStatus === 'UNASSIGNED') {
          this.store$.dispatch(
            new CreateAssignServiceOption(shipment._id, { ...option, selected: false, disabled: true })
          );
          option.services.forEach(service => {
            this.store$.dispatch(
              new CreateAssignedService(option._id, { ...service, selected: false, disabled: true })
            );
          });
        } else {
          this.store$.dispatch(new SelectSelectableShipment({ ...shipment, selected: true, disabled: true }));
          this.store$.dispatch(
            new CreateAssignServiceOption(shipment._id, { ...option, selected: true, disabled: true })
          );
          option.services.forEach(service => {
            if (service?.tenderStatus === 'UNASSIGNED') {
              this.store$.dispatch(
                new CreateAssignedService(option._id, { ...service, selected: false, disabled: false })
              );
            } else {
              this.store$.dispatch(
                new CreateAssignedService(option._id, { ...service, selected: true, disabled: true })
              );
            }
          });
        }
      });
    }
  }

  onShipmentSelectionChange(shipment: Shipment, isSelected: boolean): void {
    if (isSelected) {
      this.store$.dispatch(new SelectSelectableShipment({ ...shipment, selected: true, disabled: false }));
    } else {
      this.store$.dispatch(new DeselectSelectableShipment(shipment._id));
    }
  }

  onServiceSelectionChange(shipment: Shipment, option: ServiceOption, service: ServiceInfo, isSelected: boolean): void {
    this.store$.dispatch(
      new SelectService(shipment._id, option._id, { ...service, selected: isSelected, disabled: false })
    );
  }

  selectOption(shipment: Shipment, option: ServiceOption, isSelected: boolean): void {
    this.store$.dispatch(
      new SelectOption(shipment?._id, {
        ...option,
        selected: isSelected,
        disabled: option.servicesTenderStatus !== 'UNASSIGNED',
      })
    );
  }

  getServiceByOptionId(option: ServiceOption): ServiceInfoView[] {
    return this.store$.selectSnapshot(WizardState.getSelectedServicesByOptionId)[option._id] || [];
  }

  getOptionByShipmentId(shipment: Shipment): ServiceOptionView[] {
    return this.store$.selectSnapshot(WizardState.getSelectedOptionsByShipmentId)[shipment._id] || [];
  }

  getServiceStatus(option: ServiceOption, service: ServiceInfo): ServiceInfoView {
    return this.store$
      .selectSnapshot(WizardState.getSelectedServicesByOptionId)
      [option._id]?.find(s => s.plannedServiceId === service.plannedServiceId);
  }

  getOptionStatus(shipment: Shipment, option: ServiceOption): ServiceOptionView {
    return this.store$
      .selectSnapshot(WizardState.getSelectedOptionsByShipmentId)
      [shipment._id]?.find(o => o._id === option._id);
  }

  getShipmentStatus(shipment: Shipment): ShipmentView {
    return this.store$.selectSnapshot(WizardState.getSelectableShipments)[shipment._id];
  }

  viewOrderData(orders: Order[]): void {
    const data = {
      orders,
    };
    this.dialogHandlerService.openDialog(ViewOrderCardsComponent, data, {
      backdropClass: 'backdropBackground',
      width: '800px',
      height: '100%',
      panelClass: 'custom-dialog-container',
    });
  }

  selectAll(event): void {
    this.serviceConfigs.forEach(service => (service.selected = event.checked));
    if (this.serviceConfigs.some(service => service.selected)) {
      this.store$.dispatch(
        new UpdateUnSmartServices(this.serviceConfigs.filter(service => service.selected).map(service => service._id))
      );
      this.field.options.formState.validForTender = true;
    } else {
      this.field.options.formState.validForTender = false;
      this.store$.dispatch(new UpdateUnSmartServices([]));
    }
  }

  checkService(service: SelectableServiceConfig): void {
    this.allSelected = this.serviceConfigs.every(service => service.selected);
    if (this.serviceConfigs.some(service => service.selected)) {
      this.field.options.formState.validForTender = true;
    } else {
      this.field.options.formState.validForTender = false;
    }

    this.serviceConfigs.forEach(srv => {
      if (srv._id === service._id) {
        srv = service;
      }
    });
    this.store$.dispatch(
      new UpdateUnSmartServices(this.serviceConfigs.filter(service => service.selected).map(service => service._id))
    );
  }

  disableTenderedUnSmartenedServices(): void {
    this.gqlService
      .query<
        { findTendersByJobRefId: { tenderMetaData: { unSmartenedMetadata: { serviceIds: string[] } } }[] },
        { jobRefId: string }
      >(FIND_TENDERS_BY_JOB_REF_ID_QUERY, { jobRefId: this.activatedRoute.snapshot.params['jobRefId'] })
      .pipe(
        take(1),
        tap(result => {
          const uniqueServiceCards = new Set(
            result.findTendersByJobRefId.map(tender => tender.tenderMetaData.unSmartenedMetadata.serviceIds).flat()
          );
          this.serviceConfigs.forEach(service => {
            if (uniqueServiceCards.has(service._id)) {
              service.selected = true;
              service.disabled = true;
              this.allDisabled = true;
            }
          });
          if (this.serviceConfigs.every(service => service.disabled)) {
            this.allSelected = true;
            this.allDisabled = true;
          }
          this.cdr.detectChanges();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.store$.dispatch(new ClearJobUpdates());
    this.destroy$.next();
    this.destroy$.complete();
  }
}
