import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Order } from '../../models/order.model';
import { ShipmentMethodDetail } from '../../models/shipment-method-details';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-order-shipment-method-selector',
  templateUrl: './order-shipment-method-selector.component.html',
  styleUrl: './order-shipment-method-selector.component.scss',
})
export class OrderShipmentMethodSelectorComponent extends FieldType implements OnInit, OnDestroy {
  @Select(WizardState.getSelectedOrder) selectedOrder$: Observable<Order>;

  initialLoadType: string = 'FCL';
  initialLoadDetails: ShipmentMethodDetail[] = [];
  viewOnly = false;
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    // If the model is not set, we're in create mode.
    if (!this.model) {
      if (this.field.form instanceof FormGroup) {
        this.field.form.addControl('shipmentMethod', new FormControl(null, Validators.required));
        this.addRooValidators();
      } else {
        console.error('Form is not an instance of FormGroup');
      }
    }
    this.selectedOrder$.pipe(takeUntil(this.destroy$)).subscribe(order => {
      if (order) {
        this.field.form.get('shipmentMethod')?.patchValue(order?.loadDetails[0]?.containerType ? 'FCL' : 'LCL');
        this.field.form.get('loadDetails')?.patchValue(order?.loadDetails);
        this.initialLoadType = order?.loadDetails[0]?.containerType ? 'FCL' : 'LCL';
        this.initialLoadDetails = order?.loadDetails || [];
      }
    });
    this.viewOnly = this.props?.disabled;
  }

  onLoadDetailsChange(shipmentData: { loadDetails: ShipmentMethodDetail[]; shipmentMethod: string }): void {
    const { loadDetails, shipmentMethod } = shipmentData || {};
    if (!loadDetails || !shipmentMethod) return;

    // const target = this.model || this.field.form.controls;
    const loadDetailsControl = this.field.form.controls['loadDetails'] as FormControl;
    const shipmentMethodControl = this.field.form.controls['shipmentMethod'] as FormControl;

    const isValid = this.validateShipmentData(shipmentMethod, loadDetails);

    if (isValid) {
      this.field.form.controls['loadDetails']?.patchValue?.(loadDetails);
      this.field.form.controls['shipmentMethod']?.patchValue?.(shipmentMethod);
      loadDetailsControl?.setErrors(null);
      shipmentMethodControl?.setErrors(null);
    } else {
      loadDetailsControl?.setErrors({ invalid: true });
      shipmentMethodControl?.setErrors({ invalid: true });
    }
  }

  private validateShipmentData(shipmentMethod: string, loadDetails: ShipmentMethodDetail[]): boolean {
    const isValidFCL =
      shipmentMethod === 'FCL' &&
      loadDetails.length > 0 &&
      loadDetails.every(detail => detail.containerType && detail.noOfLoads > 0);

    const isValidLCL =
      shipmentMethod === 'LCL' &&
      loadDetails.length === 1 &&
      loadDetails[0].volumeWeight > 0 &&
      loadDetails[0].noOfLoads > 0;

    return isValidFCL || isValidLCL;
  }

  addRooValidators(): void {
    const loadDetailsControl = this.field.form.controls['loadDetails'] as FormControl;
    // Make loadDetails control required
    if (loadDetailsControl) {
      loadDetailsControl.setValidators([Validators.required]);
      loadDetailsControl.updateValueAndValidity();
    }

    // Make shipmentMethod control required
    const shipmentMethodControl = this.field.form.controls['shipmentMethod'] as FormControl;
    if (shipmentMethodControl) {
      shipmentMethodControl.setValidators([Validators.required]);
      shipmentMethodControl.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
