import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { Observable, Subject, take } from 'rxjs';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { ShipmentMethodDetail } from '../../models/shipment-method-details';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-service-shipment-method-selector',
  templateUrl: './service-shipment-method-selector.component.html',
  styleUrl: './service-shipment-method-selector.component.scss',
})
export class ServiceShipmentMethodSelectorComponent extends FieldType implements OnInit, OnDestroy {
  @Select(WizardState.getServiceCard) selectedServiceCard$: Observable<ServiceCard>;
  initialLoadType: string = 'LCL';
  initialLoadDetails: ShipmentMethodDetail[] = [];
  disabled = false;
  radioHidden = false;
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    if (this.field.form instanceof FormGroup) {
      this.field.form.addControl('shipmentMethod', new FormControl(null, Validators.required));
      this.addRooValidators();
    } else {
      console.error('Form is not an instance of FormGroup');
    }

    this.selectedServiceCard$.pipe(take(1)).subscribe(serviceCard => {
      if (serviceCard) {
        const isAirFreight = serviceCard.serviceId === 'AIR-FREIGHT';
        const hasContainerType = serviceCard.loadDetails[0]?.containerType;

        // Set initial load type
        this.initialLoadType = isAirFreight || !hasContainerType ? 'LCL' : 'FCL';

        // Set disabled state
        this.disabled = isAirFreight || this.initialLoadType === 'LCL';

        this.radioHidden = isAirFreight;

        // Set initial load details
        if (isAirFreight && hasContainerType) {
          this.initialLoadDetails = [{ containerType: '', noOfLoads: null, volumeWeight: null }];
        } else {
          const combinedLoadDetails = serviceCard.loadDetails.reduce(
            (acc, detail) => {
              acc.containerType = detail?.containerType ?? '';
              acc.noOfLoads = acc.noOfLoads + (detail?.noOfLoads ?? 0);
              acc.volumeWeight = acc.volumeWeight + (detail?.volumeWeight ?? 0);
              return acc;
            },
            { containerType: '', noOfLoads: 0, volumeWeight: 0 }
          );
          this.initialLoadDetails = [combinedLoadDetails];
          this.onLoadDetailsChange({
            loadDetails: this.initialLoadDetails,
            shipmentMethod: this.initialLoadType,
          });
        }
      }
    });
  }

  onLoadDetailsChange(shipmentData: { loadDetails: ShipmentMethodDetail[]; shipmentMethod: string }): void {
    const { loadDetails, shipmentMethod } = shipmentData || {};
    if (!loadDetails || !shipmentMethod) return;

    // const target = this.model || this.field.form.controls;
    const loadDetailsControl = this.field.form.controls['loadDetails'] as FormControl;
    const shipmentMethodControl = this.field.form.controls['shipmentMethod'] as FormControl;

    const isValid = this.validateShipmentData(shipmentMethod, loadDetails);

    if (isValid) {
      this.field.form.controls['loadDetails']?.patchValue?.(loadDetails);
      this.field.form.controls['shipmentMethod']?.patchValue?.(shipmentMethod);
      loadDetailsControl?.setErrors(null);
      shipmentMethodControl?.setErrors(null);
    } else {
      loadDetailsControl?.setErrors({ invalid: true });
      shipmentMethodControl?.setErrors({ invalid: true });
    }
  }

  private validateShipmentData(shipmentMethod: string, loadDetails: ShipmentMethodDetail[]): boolean {
    const isValidFCL =
      shipmentMethod === 'FCL' &&
      loadDetails.length > 0 &&
      loadDetails.every(detail => detail.containerType && detail.noOfLoads > 0);

    const isValidLCL =
      shipmentMethod === 'LCL' &&
      loadDetails.some(detail => detail.volumeWeight > 0) &&
      loadDetails.some(detail => detail.noOfLoads > 0);

    return isValidFCL || isValidLCL;
  }

  addRooValidators(): void {
    const loadDetailsControl = this.field.form.controls['loadDetails'] as FormControl;
    // Make loadDetails control required
    if (loadDetailsControl) {
      loadDetailsControl.setValidators([Validators.required]);
      loadDetailsControl.updateValueAndValidity();
      loadDetailsControl.markAsTouched();
      loadDetailsControl.markAsDirty();
    }

    // Make shipmentMethod control required
    const shipmentMethodControl = this.field.form.controls['shipmentMethod'] as FormControl;
    if (shipmentMethodControl) {
      shipmentMethodControl.setValidators([Validators.required]);
      shipmentMethodControl.updateValueAndValidity();
      shipmentMethodControl.markAsTouched();
      shipmentMethodControl.markAsDirty();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
